import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  standalone: false,
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit , OnChanges{
  @Input() pageNumber: number = 1;
  @Input() totalPages: number = 1;
  @Input() pageSize: number = 10;
  @Input() totalItems: number = 1;

  @Output('onPageChange') onPageChange: any = new EventEmitter();

  tempPagesArray: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.regeneratePagesArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tempPagesArray = Array(this.totalPages).fill(0).map((x, i) => i + 1);
  }

  regeneratePagesArray() {
    // 1 2 ... 5 6 7 ... 9 10

    if (this.totalPages > 10) {
      this.tempPagesArray = Array(this.totalPages).fill(0).map((x, i) => i + 1);

      if (this.pageNumber < 4) {
        this.tempPagesArray = [...this.tempPagesArray.slice(0, 4), '...', this.totalPages]
      } else if (this.pageNumber > this.totalPages - 3) {
        this.tempPagesArray = [1, '...', ...this.tempPagesArray.slice(this.totalPages - 4)]
      } else {
        this.tempPagesArray = [1, '...', ...this.tempPagesArray.slice(this.pageNumber - 2, this.pageNumber + 1), '...', this.totalPages]
      }

    }
  }

  gotoPage(pageNumber: number) {
    if (pageNumber != this.pageNumber) {
      this.onPageChange.emit(pageNumber);
    }
  }

}
